import React from "react";
/*import logoIos from "../assets/imgs/comingIOS.png";
import logoAndroid from "../assets/imgs/comingAndroid.png";*/

export const DownloadsLogosSoon = (props) => {
  //let size = 200;
  return (
    <div
      className="  col-xs-12 "
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 20,
        marginBottom: 20,
      }}
    >
      <button className="btn btn-prox btn-lg" onClick={() => {}}>
        {props.text}
      </button>
    </div>
  );
};
/* return (
    <div className="  col-xs-12 ">
      <div
        style={{
          //height: 70,
          //  paddingVertical: 15,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: 40,
          marginBottom: 120,
        }}
      >
        <div
          style={{
            width: size,
            marginRight: 8,
            marginLeft: 8,
            marginVertical: 15,
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={logoIos}
            width={size}
            alt="LogoIos"
           
          />
        </div>
        <div
          style={{
            width: size,
            marginRight: 8,
            marginLeft: 8,
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={logoAndroid}
            width={size}
            alt="LogoAndroid"
          
          />
        </div>
      </div>
        </div>*
        );*/
