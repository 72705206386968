import React from "react";
import { brands } from "../data/brands";
import constants from "../data/constants";

const ProductItem = ({ product, id }) => {
  let brand = product[0];
  if (id === "forYou") {
    brand = product[0];
  } else if (typeof brand === "number") {
    brand = brands[brand];
  }
  let img =
    "https://m.media-amazon.com/images/I/" + product[2] + "._AC_UX569_.jpg";
  let link = "https://amazon.es/dp/" + product[1] + "?tag=" + constants.es;
  return (
    <div
      className="product-item"
      //   onClick={() => console.log({ brand, img, link, product })}
    >
      <a href={link} target="_blank" rel="noreferrer">
        <img src={img} alt={brand} />
        <div className="brand-details">
          <h3
            style={{
              fontFamily: "Amazon Ember,Arial,sans-serif",
              fontSize: "16px",
              lineHeight: "24px",
              marginBottom: "6px",
            }}
          >
            {brand}
          </h3>
        </div>
        <div className="product-details">
          <img
            alt={"Amazon"}
            style={{
              width: "auto", //"100%",
              height: "20px",
              marginRight: "8px",
            }}
            src={"img/amazonesb.jpg"}
          />

          <i
            className="fa fa-shopping-cart"
            style={{ fontSize: "22px", color: "#333333" }}
          ></i>
        </div>
      </a>
    </div>
  );
};

export default ProductItem;
