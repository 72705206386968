import React from "react";

export const TermsTextEs = (props) => {
  return (
    <div id="terms" className="text-center">
      <div className="containerInfo">
        <div className="section-title">
          <article className="arti">
            <h1 className="headerInfo">Términos y Condiciones</h1>
            <p>
              Al descargar o utilizar la aplicación, estos términos se aplicarán
              automáticamente a usted; por lo tanto, asegúrese de leerlos
              cuidadosamente antes de utilizar la aplicación. No se te permite
              copiar o modificar la aplicación, ninguna parte de la aplicación,
              ni nuestras marcas registradas de ninguna manera. No se te permite
              intentar extraer el código fuente de la aplicación, y tampoco
              debes intentar traducir la aplicación a otros idiomas o crear
              versiones derivadas. La propia aplicación, y todas las marcas
              registradas, derechos de autor, derechos de base de datos y otros
              derechos de propiedad intelectual relacionados con ella, siguen
              siendo propiedad de ColorYou.
            </p>
            <br />
            <p>
              El desarrollador de ColorYou se compromete a garantizar que la
              aplicación sea lo más útil y eficiente posible. Por esa razón, nos
              reservamos el derecho de realizar cambios en la aplicación o
              cobrar por sus servicios, en cualquier momento y por cualquier
              motivo. Nunca te cobraremos por la aplicación o sus servicios sin
              dejarte muy claro exactamente por lo que estás pagando.
            </p>
            <br />
            <p>
              La aplicación ColorYou almacena en tu dispositivo, y solo en tu
              dispositivo, datos personales que nos has proporcionado, para
              proporcionar el Servicio. Es tu responsabilidad mantener seguro tu
              teléfono y el acceso a la aplicación. Por lo tanto, te
              recomendamos que no hagas jailbreak o root a tu teléfono, que es
              el proceso de eliminar restricciones y limitaciones de software
              impuestas por el sistema operativo oficial de tu dispositivo. Esto
              podría hacer que tu teléfono sea vulnerable a programas
              maliciosos/virus/programas malintencionados, comprometer las
              características de seguridad de tu teléfono y podría significar
              que la aplicación ColorYou no funcione correctamente o en
              absoluto.
            </p>{" "}
            <span className="sep"></span>
            <br />
            <div>
              <p>
                La aplicación utiliza servicios de terceros que declaran sus
                Términos y Condiciones.
              </p>{" "}
              <br />
              <p>
                Enlace a los Términos y Condiciones de los proveedores de
                servicios de terceros utilizados por la aplicación
              </p>{" "}
              <br />
              <ul>
                <li>
                  <a
                    href="https://policies.google.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Servicios de Google Play
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.revenuecat.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    RevenueCat
                  </a>
                </li>
              </ul>
            </div>
            <span className="sep"></span>
            <br />
            <p>
              Debes tener en cuenta que hay ciertas cosas de las que ColorYou no
              se hará responsable. Algunas funciones de la aplicación requerirán
              que la aplicación tenga una conexión a Internet activa. La
              conexión puede ser Wi-Fi o proporcionada por tu proveedor de red
              móvil, pero ColorYou no puede responsabilizarse de que la
              aplicación no funcione a pleno rendimiento si no tienes acceso a
              Wi-Fi y no te queda ningún dato en tu tarifa.
            </p>
            <p></p>
            <p>
              Si utilizas la aplicación fuera de un área con Wi-Fi, debes
              recordar que seguirán aplicándose los términos del acuerdo con tu
              proveedor de red móvil. Como resultado, es posible que tu
              proveedor de telefonía móvil te cobre por el costo de los datos
              durante la conexión mientras accedes a la aplicación, o por otros
              cargos de terceros. Al utilizar la aplicación, aceptas la
              responsabilidad de cualquier cargo, incluidos los cargos por datos
              en itinerancia si utilizas la aplicación fuera de tu territorio
              (es decir, región o país) sin desactivar los datos en itinerancia.
              Si no eres el pagador de la factura del dispositivo en el que
              estás utilizando la aplicación, ten en cuenta que asumimos que has
              recibido permiso del pagador de la factura para utilizar la
              aplicación.
            </p>
            <p>
              Del mismo modo, ColorYou no puede asumir siempre la
              responsabilidad por la forma en que utilizas la aplicación, es
              decir, debes asegurarte de que tu dispositivo esté cargado; si se
              queda sin batería y no puedes encenderlo para acceder al Servicio,
              ColorYou no puede aceptar responsabilidad.
            </p>
            <p>
              En lo que respecta a la responsabilidad de ColorYou por tu uso de
              la aplicación, cuando uses la aplicación, es importante tener en
              cuenta que, aunque nos esforzamos por garantizar que esté
              actualizada y sea correcta en todo momento, dependemos de terceros
              para que nos proporcionen información para que podamos ponerla a
              tu disposición. ColorYou no acepta ninguna responsabilidad por
              ninguna pérdida, directa o indirecta, que experimentes como
              resultado de confiar completamente en esta funcionalidad de la
              aplicación.
            </p>
            <span className="sep"></span>
            <br />
            <p>
              En algún momento, es posible que deseemos actualizar la
              aplicación. La aplicación está disponible actualmente en Android e
              iOS; los requisitos para ambos sistemas (y para cualquier sistema
              adicional que decidamos extender la disponibilidad de la
              aplicación) pueden cambiar, y necesitarás descargar las
              actualizaciones si quieres seguir utilizando la aplicación. El
              desarrollador de ColorYou no promete que siempre actualizará la
              aplicación para que sea relevante para ti y/o funcione con la
              versión de Android e iOS que tengas instalada en tu dispositivo.
              Sin embargo, te comprometes a aceptar siempre las actualizaciones
              de la aplicación cuando te las ofrezcan. También es posible que
              deseemos dejar de proporcionar la aplicación y podamos terminar su
              uso en cualquier momento sin previo aviso. A menos que te
              informemos lo contrario, al terminar, (a) los derechos y licencias
              otorgados a ti en estos términos finalizarán; (b) debes dejar de
              usar la aplicación y (si es necesario) eliminarla de tu
              dispositivo.
            </p>
            <h1 className="headerInfo2">
              Cambios en estos Términos y Condiciones
            </h1>
            <p>
              Podemos actualizar nuestros Términos y Condiciones de vez en
              cuando. Por lo tanto, se te aconseja que revises esta página
              periódicamente para ver cualquier cambio. Te notificaré cualquier
              cambio publicando los nuevos Términos y Condiciones en esta
              página.
            </p>
            <p>
              Estos términos y condiciones son efectivos a partir del 2024-03-01
            </p>
            <h1 className="headerInfo2">Contáctanos</h1>
            <p>
              Si tienes alguna pregunta o sugerencia sobre mis Términos y
              Condiciones, no dudes en contactarnos en
              <span style={{ color: "#DD3D99" }}> coloryouapp@gmail.com</span>.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};
