import { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import "./navbar.css";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const { t } = useTranslation();
  const [showNavbar, setShowNavbar] = useState(false);
  const location = useLocation();

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    // Hace scroll hasta la parte superior cada vez que cambia la ubicación
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            onClick={handleShowNavbar}
          >
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <Link
            to={"/#page-top"}
            className="page-scroll logoText"
            onClick={scrollToTop}
          >
            <img src={"img/logo.png"} alt={"logo"} className="logo" />
            ColorYou
          </Link>
        </div>

        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul className="navbar-nav navbar-right">
            <li>
              <Link to="/">{t("App")}</Link>
            </li>
            <li>
              <Link to="/finder">{t("Finder")}</Link>
            </li>
            <li>
              <Link to="/info">{t("Info")}</Link>
            </li>
            <li>
              <LanguageSwitcher />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
