import React from "react";

export const TermsTextFr = (props) => {
  return (
    <div id="terms" className="text-center">
      <div className="containerInfo">
        <div className="section-title">
          <article className="arti">
            <h1 className="headerInfo">Conditions Générales</h1>
            <p>
              En téléchargeant ou en utilisant l'application, ces termes
              s'appliqueront automatiquement à vous ; assurez-vous donc de les
              lire attentivement avant d'utiliser l'application. Il ne vous est
              pas autorisé de copier ou de modifier l'application, aucune partie
              de l'application, ni nos marques commerciales d'aucune manière. Il
              ne vous est pas autorisé de tenter d'extraire le code source de
              l'application, et vous ne devez pas non plus tenter de traduire
              l'application dans d'autres langues ou de créer des versions
              dérivées. L'application elle-même, ainsi que toutes les marques
              commerciales, droits d'auteur, droits de base de données et autres
              droits de propriété intellectuelle qui y sont liés, restent la
              propriété de ColorYou.
            </p>
            <br />
            <p>
              Le développeur de ColorYou s'engage à garantir que l'application
              soit aussi utile et efficace que possible. Pour cette raison, nous
              nous réservons le droit d'apporter des modifications à
              l'application ou de facturer ses services, à tout moment et pour
              quelque raison que ce soit. Nous ne vous facturerons jamais
              l'application ou ses services sans vous préciser clairement ce
              pour quoi vous payez exactement.
            </p>
            <br />
            <p>
              L'application ColorYou stocke dans votre appareil, et uniquement
              dans votre appareil, des données personnelles que vous nous avez
              fournies, pour fournir le Service. Il est de votre responsabilité
              de maintenir la sécurité de votre téléphone et l'accès à
              l'application. Nous vous recommandons donc de ne pas jailbreaker
              ou rooter votre téléphone, ce qui consiste à supprimer les
              restrictions et limitations logicielles imposées par le système
              d'exploitation officiel de votre appareil. Cela pourrait rendre
              votre téléphone vulnérable aux logiciels
              malveillants/virus/programmes malveillants, compromettre les
              fonctionnalités de sécurité de votre téléphone et pourrait
              signifier que l'application ColorYou ne fonctionnera pas
              correctement ou pas du tout.
            </p>{" "}
            <span className="sep"></span>
            <br />
            <div>
              <p>
                L'application utilise des services tiers qui déclarent leurs
                conditions générales.
              </p>{" "}
              <br />
              <p>
                Lien vers les conditions générales des fournisseurs de services
                tiers utilisés par l'application
              </p>{" "}
              <br />
              <ul>
                <li>
                  <a
                    href="https://policies.google.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Services Google Play
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.revenuecat.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    RevenueCat
                  </a>
                </li>
              </ul>
            </div>
            <span className="sep"></span>
            <br />
            <p>
              Vous devez savoir qu'il y a certaines choses pour lesquelles
              ColorYou ne sera pas responsable. Certaines fonctions de
              l'application nécessiteront que l'application ait une connexion
              Internet active. La connexion peut être Wi-Fi ou fournie par votre
              opérateur de réseau mobile, mais ColorYou ne peut être tenu
              responsable si l'application ne fonctionne pas à pleine capacité
              si vous n'avez pas accès au Wi-Fi et si vous n'avez plus de
              données dans votre forfait.
            </p>
            <p></p>
            <p>
              Si vous utilisez l'application en dehors d'une zone Wi-Fi, vous
              devez vous rappeler que les conditions de l'accord avec votre
              fournisseur de réseau mobile s'appliqueront toujours. Par
              conséquent, vous pourriez être facturé par votre fournisseur de
              téléphonie mobile pour le coût des données pendant la connexion
              pendant que vous accédez à l'application, ou pour d'autres frais
              de tiers. En utilisant l'application, vous acceptez la
              responsabilité de ces frais, y compris les frais de données en
              itinérance si vous utilisez l'application en dehors de votre
              territoire domestique (c'est-à-dire région ou pays) sans
              désactiver l'itinérance des données. Si vous n'êtes pas le payeur
              de la facture de l'appareil sur lequel vous utilisez
              l'application, veuillez noter que nous supposons que vous avez
              reçu l'autorisation du payeur de la facture pour utiliser
              l'application.
            </p>
            <p>
              De même, ColorYou ne peut pas toujours être tenu responsable de la
              manière dont vous utilisez l'application, c'est-à-dire que vous
              devez vous assurer que votre appareil reste chargé ; s'il est à
              court de batterie et que vous ne pouvez pas l'allumer pour
              utiliser le Service, ColorYou ne peut pas accepter la
              responsabilité.
            </p>
            <p>
              En ce qui concerne la responsabilité de ColorYou pour votre
              utilisation de l'application, lorsque vous utilisez l'application,
              il est important de garder à l'esprit que, bien que nous nous
              efforcions de garantir qu'elle soit mise à jour et correcte en
              tout temps, nous comptons sur des tiers pour nous fournir des
              informations afin que nous puissions les rendre disponibles pour
              vous. ColorYou n'accepte aucune responsabilité pour toute perte,
              directe ou indirecte, que vous pourriez subir en raison de votre
              confiance totale dans cette fonctionnalité de l'application.
            </p>
            <span className="sep"></span>
            <br />
            <p>
              À un moment donné, nous pouvons souhaiter mettre à jour
              l'application. L'application est actuellement disponible sur
              Android et iOS ; les exigences pour les deux systèmes (et pour
              tout système supplémentaire que nous décidons d'étendre la
              disponibilité de l'application à) peuvent changer, et vous devrez
              télécharger les mises à jour si vous voulez continuer à utiliser
              l'application. Le développeur de ColorYou ne promet pas toujours
              de mettre à jour l'application afin qu'elle soit pertinente pour
              vous et/ou qu'elle fonctionne avec la version Android et iOS que
              vous avez installée sur votre appareil. Cependant, vous vous
              engagez à toujours accepter les mises à jour de l'application
              lorsqu'elles vous sont proposées. Nous pouvons également souhaiter
              cesser de fournir l'application, et pouvons mettre fin à son
              utilisation à tout moment sans préavis. Sauf indication contraire
              de notre part, à la fin de la période de validité, (a) les droits
              et licences qui vous ont été accordés en vertu de ces conditions
              prendront fin ; (b) vous devez cesser d'utiliser l'application et
              (si nécessaire) la supprimer de votre appareil.
            </p>
            <h1 className="headerInfo2">
              Modifications de ces Conditions Générales
            </h1>
            <p>
              Nous pouvons mettre à jour nos Conditions Générales de temps à
              autre. Par conséquent, il est conseillé de consulter cette page
              périodiquement pour tout changement. Nous vous informerons de tout
              changement en publiant les nouvelles Conditions Générales sur
              cette page.
            </p>
            <p>
              Ces termes et conditions sont en vigueur à partir du 2024-03-01
            </p>
            <h1 className="headerInfo2">Contactez-nous</h1>
            <p>
              Si vous avez des questions ou des suggestions concernant mes
              Conditions Générales, n'hésitez pas à nous contacter à l'adresse
              <span style={{ color: "#DD3D99" }}> coloryouapp@gmail.com</span>.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};
