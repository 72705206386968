import React from "react";
import { useTranslation } from "react-i18next";

export const Features = (props) => {
  const data = [
    "fa fa-smile-o",
    "fa fa-dollar",
    "fa fa-clock-o",
    "fa fa-magic",
  ];
  const { t } = useTranslation();
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="row" style={{ marginTop: 20, marginBottom: 30 }}>
          {data.map((d, i) => (
            <div key={`${"TitleFeature"}-${i}`} className="col-xs-6 col-md-3">
              {" "}
              <i className={d}></i>
              <h3>{t("TitleFeature" + i)}</h3>
              <p>{t("DescFeature" + i)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
