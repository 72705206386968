const contactEmail = "coloryouapp@gmail.com";
const year = "2024";
const es = "coloryou07-21";

const constants = {
  year,
  contactEmail,
  es,
};

export default constants;
