import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
/*HashRouter instead of BrowserRouter because sublinks return 404 error in production*/
import "./index.css";
import App from "./App";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <HashRouter>
      <App />
    </HashRouter>
  </I18nextProvider>,
  document.getElementById("root")
);
