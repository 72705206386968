import React from "react";
import { Masking } from "./Masking";

export const InfoTextFr = (props) => {
  return (
    <div id="privacy" className="text-center">
      <div className="containerInfo">
        <div className="section-title">
          <article className="arti">
            <section>
              <h2 className="subHeaderInfo">Qu'est-ce que la colorimétrie ?</h2>
              <p>
                La colorimétrie est une discipline qui étudie comment nous
                percevons les couleurs et comment elles interagissent avec nos
                caractéristiques personnelles, telles que la carnation, la
                couleur des yeux et des cheveux. Grâce à la colorimétrie, il est
                possible de déterminer les{" "}
                <b>couleurs qui nous vont le mieux</b>, mettant en valeur nos
                caractéristiques naturelles.
              </p>
              <p>
                Cette science est basée sur des principes fondamentaux comme le
                contraste simultané, qui explique comment une couleur peut
                affecter la perception d'une autre lorsqu'elles sont vues
                ensemble, et comment notre perception des couleurs est
                influencée par la lumière et l'environnement qui nous entoure.
              </p>
            </section>
            <br></br>
            <section>
              <h2 className="subHeaderInfo">Le Contraste Simultané</h2>
              <p>
                C'est le phénomène visuel où nous{" "}
                <b>
                  percevons les couleurs différemment selon les couleurs qui les
                  entourent
                </b>
                . Ce principe est clé dans la colorimétrie personnelle.
              </p>
              <p>
                Lorsque deux couleurs sont placées côte à côte, chaque couleur
                ne semble pas identique à si elle était seule. Cela est dû au
                fait que nos yeux, en les voyant ensemble, créent un effet où
                les couleurs peuvent sembler plus lumineuses, plus sombres, plus
                vives ou plus ternes, en fonction des couleurs à proximité.
              </p>
              <Masking lang="fr" />
              <br></br>
              <p>
                Les deux yeux semblent-ils de couleurs différentes ? Comme vous
                l'avez deviné, les deux yeux sont de la même couleur grise, mais
                en entourant un œil de rouge (sans couvrir l'iris), celui-ci
                paraît bleu.
              </p>
            </section>
            <br></br>
            <section>
              <h2 className="subHeaderInfo">Les 12 Saisons de Couleur</h2>
              <p>
                En colorimétrie personnelle, une approche de classification très
                populaire est celle des 12 saisons de couleur, qui se divisent
                en quatre grands groupes :
                <b>Printemps, Été, Automne et Hiver</b>. Chaque groupe reflète
                un ensemble de caractéristiques chromatiques spécifiques.
              </p>
              <p>
                Les saisons de couleur aident à identifier les tons qui
                complètent le mieux nos caractéristiques naturelles.
              </p>

              <div className="containerSeasonsImg">
                <img
                  src="img/Info/seasonsFR.jpg"
                  alt="Saisons"
                  className="seasonsimage"
                  onDragStart={(e) => e.preventDefault()}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
            </section>
            <br></br>
            <section>
              <h2 className="subHeaderInfo">
                Comment déterminer votre saison de couleur ?
              </h2>
              <p>
                -<b> MÉTHODE APPROXIMATIVE</b>
                <br />
                <br /> Une première façon de procéder à la classification
                consiste à observer les caractéristiques physiques de la
                personne : par exemple, la carnation peut être plus chaude ou
                froide, la couleur des cheveux peut être claire, foncée, chaude
                ou cendrée, et les yeux peuvent avoir une teinte plus chaude ou
                froide. Cependant, cette méthode, bien que utile, peut ne pas
                toujours être entièrement précise.
              </p>
              <br />
              <p>
                -<b> MÉTHODE PROFESSIONNELLE</b>
                <br />
                <br /> Pour une évaluation plus précise et personnalisée, les
                professionnels utilisent une technique appelée <b>draping</b>.
                Ce processus consiste à placer différents tissus de couleurs
                spécifiques près du visage de la personne pour observer comment
                chaque ton affecte son apparence. Le but est d'identifier les
                couleurs qui améliorent la luminosité et l'harmonie du visage,
                plutôt que celles qui pourraient accentuer les ombres, les
                rougeurs ou d'autres imperfections. <br />
                <br />
                <span className="subHeaderInfo">1</span> - Le processus de
                draping commence par l'identification de la{" "}
                <b>température de couleur</b> de la personne, c'est-à-dire si
                elle est plus mise en valeur par des tons chauds (jaunes, dorés)
                ou froids (bleus, argentés). <br />
                <br />
                <span className="subHeaderInfo">2</span> - Une fois qu'il a été
                déterminé si la personne est de "température chaude" ou de
                "température froide", l'étape suivante consiste à analyser l'
                <b>intensité</b> (couleurs vives ou atténuées) et la{" "}
                <b>profondeur</b> (couleurs claires ou foncées) qui lui vont le
                mieux. <br />
                <br />
                <span className="subHeaderInfo">3</span> - Enfin, les couleurs
                des différentes <b>sous-saisons</b> sont comparées pour affiner
                encore davantage la palette spécifique la plus flatteuse.
                <br /> <br />
                Bien qu'il soit idéal qu'un bon professionnel effectue cette
                analyse, il est maintenant possible d'obtenir une évaluation
                précise de vos couleurs sans quitter la maison grâce à la
                technologie. L'application{" "}
                <span className="subHeaderInfo">
                  <b>ColorYou</b>
                </span>{" "}
                utilise les mêmes principes et tests qu'un professionnel en
                colorimétrie. Vous pouvez choisir entre une analyse estimée ou
                une analyse complète, semblable à celle qui serait réalisée avec
                le draping en personne. De cette manière, vous pouvez découvrir
                votre saison de couleur avec précision, en obtenant un guide
                complet des couleurs qui vous vont le mieux, le tout dans le
                confort de votre maison.
                <br />
                <br /> Un outil puissant pour mettre en valeur votre beauté
                naturelle !
              </p>
            </section>
          </article>
        </div>
      </div>
    </div>
  );
};
