import React from "react";
import { Masking } from "./Masking";

export const InfoText = (props) => {
  return (
    <div id="privacy" className="text-center">
      <div className="containerInfo">
        <div className="section-title">
          <article className="arti">
            <section>
              <h2 className="subHeaderInfo">What is Colorimetry?</h2>
              <p>
                Colorimetry is a discipline that studies how we perceive colors
                and how they interact with our personal characteristics, such as
                skin tone, eye color, and hair color. Through colorimetry, it is
                possible to determine the <b>colors that suit us best</b>,
                enhancing our natural features.
              </p>
              <p>
                This science is based on fundamental principles such as
                simultaneous contrast, which explains how one color can affect
                the perception of another when seen together, and how our
                perception of color is influenced by light and the surrounding
                environment.
              </p>
            </section>
            <br></br>
            <section>
              <h2 className="subHeaderInfo">Simultaneous Contrast</h2>
              <p>
                It is the visual phenomenon where we{" "}
                <b>
                  perceive colors differently depending on the colors placed
                  next to them
                </b>
                . This principle is key in personal colorimetry.
              </p>
              <p>
                When two colors are placed side by side, the color of each one
                doesn’t look the same as if it were alone. This is because our
                eyes, when seeing them together, create an effect where the
                colors may seem brighter, darker, more vibrant, or duller,
                depending on the colors nearby.
              </p>
              <Masking lang="en" />
              <br></br>
              <p>
                Do the two eyes seem like they are different colors? As you may
                have guessed, both eyes are the same gray color, but surrounding
                one eye with red (without covering the iris) makes it appear
                blue.
              </p>
            </section>
            <br></br>
            <section>
              <h2 className="subHeaderInfo">The 12 Color Seasons</h2>
              <p>
                In personal colorimetry, a very popular classification approach
                is the 12 color seasons, which are divided into four main
                groups: <b>Spring, Summer, Autumn, and Winter</b>. Each group
                reflects a set of specific chromatic characteristics.
              </p>
              <p>
                The color seasons help to identify the tones that best
                complement our natural features.
              </p>

              <div className="containerSeasonsImg">
                <img
                  src="img/Info/seasonsENG.jpg"
                  alt="Seasons"
                  className="seasonsimage"
                  onDragStart={(e) => e.preventDefault()}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
            </section>
            <br></br>
            <section>
              <h2 className="subHeaderInfo">
                How is Your Color Season Determined?
              </h2>
              <p>
                -<b> APPROXIMATE METHOD</b>
                <br />
                <br /> An initial way to perform the classification is by
                observing the person’s physical characteristics: for example,
                the skin tone can be warmer or cooler, the hair color can be
                light, dark, warm, or ashy, and the eyes can have a warmer or
                cooler hue. However, this method, while useful, may not always
                be entirely precise.
              </p>
              <br />
              <p>
                -<b> PROFESSIONAL METHOD</b>
                <br />
                <br /> For a more accurate and personalized evaluation,
                professionals use a technique called <b>draping</b>. This
                process consists of placing different specific colored fabrics
                near the person’s face to observe how each tone affects their
                appearance. The goal is to identify the colors that enhance the
                brightness and harmony of the face, rather than those that may
                highlight shadows, redness, or other imperfections. <br />
                <br />
                <span className="subHeaderInfo">1</span> - The draping process
                begins by identifying the person’s <b>color temperature</b>,
                that is, whether they are more flattered by warm tones (yellows,
                golds) or cool tones (blues, silvers). <br />
                <br />
                <span className="subHeaderInfo">2</span> - Once it has been
                determined whether the person is "warm temperature" or "cool
                temperature", the next step is to analyze the <b>intensity</b>{" "}
                (bright or muted colors) and <b>depth</b> (light or dark colors)
                that suit them best. <br />
                <br />
                <span className="subHeaderInfo">3</span> - Finally, colors from
                different <b>subseasons</b> are compared to further refine the
                most flattering specific palette.
                <br /> <br />
                While having a good professional perform this analysis is ideal,
                it is now possible to get an accurate color assessment without
                leaving home thanks to technology. The app{" "}
                <span className="subHeaderInfo">
                  <b>ColorYou</b>
                </span>{" "}
                uses the same principles and tests as a colorimetry
                professional. You can choose between an estimated analysis or a
                full analysis, similar to what would be done with draping in
                person. This way, you can discover your color season with
                precision, obtaining a complete guide to the colors that best
                suit you, all from the comfort of your home.
                <br />
                <br /> A powerful tool to highlight your natural beauty!
              </p>
            </section>
          </article>
        </div>
      </div>
    </div>
  );
};
