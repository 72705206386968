import React from "react";

export const PrivacyTextFr = (props) => {
  return (
    <div id="privacy" className="text-center">
      <div className="containerInfo">
        <div className="section-title">
          <article className="arti">
            <h1 className="headerInfo">Politique de confidentialité</h1>
            <p>
              Les développeurs de ColorYou ont construit l'application ColorYou
              en tant qu'application Freemium. Ce SERVICE est fourni par
              ColorYou gratuitement et est destiné à être utilisé tel quel.
            </p>
            <p>
              Cette page est utilisée pour informer les visiteurs des politiques
              de collecte, d'utilisation et de divulgation des informations
              personnelles si quelqu'un décide d'utiliser le Service.
            </p>
            <p>
              Si vous choisissez d'utiliser le Service, vous acceptez la
              collecte et l'utilisation des informations conformément à cette
              politique. Les informations personnelles que nous collectons sont
              utilisées pour fournir et améliorer le Service. Nous n'utiliserons
              NI ne partagerons vos informations avec personne sauf tel que
              décrit dans cette Politique de confidentialité.
            </p>
            <p>
              Les termes utilisés dans cette Politique de confidentialité ont
              les mêmes significations que dans nos Conditions générales,
              accessibles sur ColorYou sauf indication contraire dans cette
              Politique de confidentialité.
            </p>
            <br />
            <p>
              <strong>Collecte et utilisation des informations</strong>
            </p>
            <br />
            <div>
              <p>
                Pour vous offrir une meilleure expérience lors de l'utilisation
                de notre Service, nous pouvons vous demander de nous fournir
                certaines informations personnelles identifiables, y compris
                mais sans s'y limiter, l'Image. Plus précisément, nous tenterons
                de déterminer la langue que vous utilisez et pourrions
                identifier le pays à partir duquel vous utilisez l'application
                afin de vous montrer des informations adaptées à vous. Les
                informations que nous demandons seront conservées UNIQUEMENT sur
                votre appareil et NE seront en aucun cas recueillies par nous.
                Nous NE stockerons JAMAIS les images que vous utilisez en dehors
                de votre appareil.
              </p>
              <div>
                <p>
                  L'application utilise des services tiers susceptibles de
                  collecter des informations vous identifiant.
                </p>
                <p>
                  Lien vers la politique de confidentialité des fournisseurs de
                  services tiers utilisés par l'application
                </p>
                <ul>
                  <li>
                    <a
                      href="https://www.google.com/policies/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Services Google Play
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.revenuecat.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      RevenueCat
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <p>
              <strong>Données de journal</strong>
            </p>
            <p>
              Nous tenons à vous informer que chaque fois que vous utilisez mon
              Service, en cas d'erreur dans l'application, nous pouvons
              collecter des données et des informations (via des produits tiers)
              sur votre téléphone appelées Données de journal. Ces Données de
              journal peuvent inclure des informations telles que votre adresse
              IP, le nom de votre appareil, la version du système
              d'exploitation, la configuration de l'application lors de
              l'utilisation du Service, l'heure et la date de votre utilisation
              du Service et d'autres statistiques.
            </p>
            <p>
              <strong>Cookies</strong>
            </p>
            <p>
              Les cookies sont des fichiers contenant une petite quantité de
              données qui sont couramment utilisés comme identifiants uniques
              anonymes. Ils sont envoyés à votre navigateur à partir des sites
              Web que vous visitez et sont stockés dans la mémoire interne de
              votre appareil.
            </p>
            <p>
              Ce Service n'utilise PAS explicitement ces "cookies". Cependant,
              l'application peut utiliser du code et des bibliothèques tiers qui
              utilisent des "cookies" pour collecter des informations et
              améliorer leurs services. Vous avez la possibilité d'accepter ou
              de refuser ces cookies et de savoir quand un cookie est envoyé à
              votre appareil. Si vous choisissez de refuser nos cookies, vous
              pourriez ne pas être en mesure d'utiliser certaines parties de ce
              Service.
            </p>
            <p>
              <strong>Prestataires de services</strong>
            </p>
            <p>
              Nous pouvons employer des sociétés tierces et des individus pour
              les raisons suivantes:
            </p>
            <ul>
              <li>Pour faciliter notre Service ;</li>
              <li>Pour fournir le Service en notre nom ;</li>
              <li>Pour effectuer des services liés au Service ; ou</li>
              <li>
                Pour nous aider à analyser comment notre Service est utilisé.
              </li>
            </ul>
            <p>
              Nous tenons à informer les utilisateurs de ce Service que ces
              tiers ont accès à leurs informations personnelles. La raison en
              est d'effectuer les tâches qui leur sont assignées en notre nom.
              Cependant, ils sont tenus de ne pas divulguer ou utiliser les
              informations à d'autres fins.
            </p>
            <p>
              <strong>Sécurité</strong>
            </p>
            <p>
              Nous attachons de l'importance à votre confiance en nous
              fournissant vos informations personnelles, c'est pourquoi nous
              nous efforçons d'utiliser des moyens commercialement acceptables
              pour les protéger. Mais rappelez-vous qu'aucune méthode de
              transmission sur Internet, ni méthode de stockage électronique
              n'est sécurisée à 100 % et fiable, et nous ne pouvons garantir sa
              sécurité absolue.
            </p>
            <p>
              <strong>Liens vers d'autres sites</strong>
            </p>
            <p>
              Ce Service peut contenir des liens vers d'autres sites. Si vous
              cliquez sur un lien tiers, vous serez dirigé vers ce site. Notez
              que ces sites externes ne sont pas exploités par nous. Par
              conséquent, nous vous conseillons vivement de consulter la
              Politique de confidentialité de ces sites Web. Nous n'avons aucun
              contrôle sur le contenu, les politiques de confidentialité ou les
              pratiques des sites ou services tiers.
            </p>
            <p>
              <strong>Confidentialité des enfants</strong>
            </p>
            <div>
              <p>
                Ces Services ne s'adressent à personne de moins de 13 ans. Nous
                ne collectons pas sciemment d'informations personnelles
                identifiables auprès d'enfants de moins de 13 ans. Dans le cas
                où nous découvririons qu'un enfant de moins de 13 ans nous a
                fourni des informations personnelles, nous supprimerions
                immédiatement ces informations de nos serveurs. Si vous êtes un
                parent ou un tuteur et que vous savez que votre enfant nous a
                fourni des informations personnelles, veuillez me contacter afin
                que je puisse prendre les mesures nécessaires.
              </p>
              <p>
                <strong>
                  Modifications de cette politique de confidentialité
                </strong>
              </p>
              <p>
                Nous pouvons mettre à jour notre Politique de confidentialité de
                temps à autre. Ainsi, nous vous conseillons de consulter cette
                page périodiquement pour toute modification. Nous vous
                informerons de toute modification en publiant la nouvelle
                Politique de confidentialité sur cette page.
              </p>
              <p>Cette politique est en vigueur à partir du 2024-03-01</p>
              <p>
                <strong>Nous contacter</strong>
              </p>
              <p>
                Si vous avez des questions ou des suggestions concernant notre
                Politique de confidentialité, n'hésitez pas à nous contacter à
                l'adresse{" "}
                <span style={{ color: "#DD3D99" }}> coloryouapp@gmail.com</span>
                .
              </p>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};
