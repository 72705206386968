import React from "react";

export const PrivacyText = (props) => {
  return (
    <div id="privacy" className="text-center">
      <div className="containerInfo">
        <div className="section-title">
          <article className="arti">
            <h1 className="headerInfo">Privacy Policy</h1>
            <p>
              ColorYou developers built the ColorYou app as a Freemium app. This
              SERVICE is provided by ColorYou at no cost and is intended for use
              as is.
            </p>
            <p>
              This page is used to inform visitors regarding the policies with
              the collection, use, and disclosure of Personal Information if
              anyone decided to use the Service.
            </p>
            <p>
              If you choose to use the Service, then you agree to the collection
              and use of information in relation to this policy. The Personal
              Information that we collect is used for providing and improving
              the Service. We will NOT use or share your information with anyone
              except as described in this Privacy Policy.
            </p>
            <p>
              The terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions, which are accessible at ColorYou unless
              otherwise defined in this Privacy Policy.
            </p>
            <br />
            <p>
              <strong>Information Collection and Use</strong>
            </p>
            <br />
            <div>
              <p>
                To provide you with a better experience while using our Service,
                we may ask you to provide us with certain personally
                identifiable information, including but not limited to Image.
                Specifically, we will attempt to determine the language you use
                and may identify the country from which you use the application
                in order to show you tailored information. The information we
                request will be retained SOLELY on your device and will NOT be
                collected by us in any way. We will NEVER store the images you
                use outside of your device."
              </p>
              <div>
                <p>
                  The app does use third-party services that may collect
                  information used to identify you.
                </p>
                <p>
                  Link to the privacy policy of third-party service providers
                  used by the app
                </p>
                <ul>
                  <li>
                    <a
                      href="https://www.google.com/policies/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Play Services
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.revenuecat.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      RevenueCat
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <p>
              <strong>Log Data</strong>
            </p>
            <p>
              We want to inform you that whenever you use my Service, in a case
              of an error in the app we may collect data and information
              (through third-party products) on your phone called Log Data. This
              Log Data may include information such as your device Internet
              Protocol (“IP”) address, device name, operating system version,
              the configuration of the app when utilizing the Service, the time
              and date of your use of the Service, and other statistics.
            </p>
            <p>
              <strong>Cookies</strong>
            </p>
            <p>
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory.
            </p>
            <p>
              This Service does NOT use these “cookies” explicitly. However, the
              app may use third-party code and libraries that use “cookies” to
              collect information and improve their services. You have the
              option to either accept or refuse these cookies and know when a
              cookie is being sent to your device. If you choose to refuse our
              cookies, you may not be able to use some portions of this Service.
            </p>
            <p>
              <strong>Service Providers</strong>
            </p>
            <p>
              We may employ third-party companies and individuals due to the
              following reasons:
            </p>
            <ul>
              <li>To facilitate our Service;</li>
              <li>To provide the Service on our behalf;</li>
              <li>To perform Service-related services; or</li>
              <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            <p>
              We want to inform users of this Service that these third parties
              have access to their Personal Information. The reason is to
              perform the tasks assigned to them on our behalf. However, they
              are obligated not to disclose or use the information for any other
              purpose.
            </p>
            <p>
              <strong>Security</strong>
            </p>
            <p>
              We value your trust in providing us your Personal Information,
              thus we are striving to use commercially acceptable means of
              protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security.
            </p>
            <p>
              <strong>Links to Other Sites</strong>
            </p>
            <p>
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by us. Therefore, we
              strongly advise you to review the Privacy Policy of these
              websites. We have no control over and assume no responsibility for
              the content, privacy policies, or practices of any third-party
              sites or services.
            </p>
            <p>
              <strong>Children’s Privacy</strong>
            </p>
            <div>
              <p>
                These Services do not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                children under 13 years of age. In the case we discover that a
                child under 13 has provided us with personal information, we
                immediately delete this from our servers. If you are a parent or
                guardian and you are aware that your child has provided us with
                personal information, please contact me so that I will be able
                to do the necessary actions.
              </p>
              <p>
                <strong>Changes to This Privacy Policy</strong>
              </p>
              <p>
                We may update our Privacy Policy from time to time. Thus, you
                are advised to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page.
              </p>
              <p>This policy is effective as of 2024-03-01</p>
              <p>
                <strong>Contact Us</strong>
              </p>
              <p>
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to contact us at{" "}
                <span style={{ color: "#DD3D99" }}> coloryouapp@gmail.com</span>
                .
              </p>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};
