import React, { useRef } from "react";
export const FileUpload = ({ handleFileChange, text, type }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      {type === "Initial" && (
        <i
          className="fa fa-image"
          onClick={handleButtonClick}
          style={{
            fontSize: "80px",
            color: "#333333",
            marginBottom: "20px",
          }}
        ></i>
      )}
      <div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          style={{ display: "none" }}
        />

        <button onClick={handleButtonClick} className="btn btn-custom btn-lg">
          {text}
        </button>
      </div>
    </>
  );
};
