import React from "react";
import { Masking } from "./Masking";

export const InfoTextEs = (props) => {
  return (
    <div id="privacy" className="text-center">
      <div className="containerInfo">
        <div className="section-title">
          <article className="arti">
            <section>
              <h2 className="subHeaderInfo">¿Qué es la colorimetría?</h2>
              <p lang="es">
                La colorimetría es una disciplina que se encarga de estudiar
                cómo percibimos los colores y cómo estos interactúan con
                nuestras características personales, como el tono de piel, el
                color de ojos y el cabello. A través de la colorimetría, se
                pueden determinar los <b>colores que más nos favorecen</b>,
                realzando nuestras características naturales.
              </p>
              <p lang="es">
                Esta ciencia se basa en principios fundamentales como el
                contraste simultáneo, que explica cómo un color puede afectar la
                percepción de otro cuando se ven juntos, y cómo nuestra
                percepción del color es influenciada por la luz y el entorno que
                nos rodea.
              </p>
            </section>
            <br></br>
            <section>
              <h2 className="subHeaderInfo">El contraste simultáneo</h2>
              <p lang="es">
                Es el fenómeno visual por el que
                <b>
                  {" "}
                  percibimos los colores de distinta forma según los colores que
                  tengan a su lado
                </b>
                . Este principio es clave en la colorimetría personal.
              </p>
              <p lang="es">
                Cuando dos colores están uno al lado del otro el color de cada
                uno no se ve igual que si estuviera solo. Esto se debe a que
                nuestros ojos, al verlos juntos, crean un efecto donde los
                colores pueden parecer más claros, oscuros, vibrantes o
                apagados, dependiendo de qué colores estén cerca.
              </p>
              <Masking lang="es" />
              <br></br>
              <p lang="es">
                ¿Los dos ojos parecen de distinto color? Como habrás adivinado
                ambos ojos son del mismo color gris pero al rodear el ojo de un
                color rojo (que no cubre el iris) se percibe de color azul
              </p>
            </section>
            <br></br>
            <section>
              <h2 className="subHeaderInfo">Las 12 estaciones de color</h2>
              <p>
                En la colorimetría personal, una corriente muy popular de
                clasificación de las personas es la de las 12 estaciones de
                color, que se dividen en cuatro grandes grupos:{" "}
                <b>Primavera, Verano, Otoño e Invierno</b>. Cada grupo refleja
                un conjunto de características cromáticas específicas.
              </p>
              <p>
                Las estaciones de color ayudan a identificar los tonos que mejor
                complementan nuestras características naturales.
              </p>

              <div className="containerSeasonsImg">
                <img
                  src="img/Info/seasonsCAS.jpg"
                  alt="Estaciones"
                  className="seasonsimage"
                  onDragStart={(e) => e.preventDefault()}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
            </section>
            <br></br>
            <section>
              <h2 className="subHeaderInfo">
                ¿Cómo se determina tu estación de color?
              </h2>
              <p>
                -<b> MÉTODO APROXIMADO</b>
                <br />
                <br /> Una forma inicial de realizar la clasificación es
                observando las características físicas de la persona: por
                ejemplo, el tono de piel puede ser más cálido o frío, el color
                del cabello puede ser claro, oscuro, cálido o ceniza, y los ojos
                pueden tener un matiz más cálido o frío. Sin embargo, este
                método, aunque útil, puede no ser del todo preciso.
              </p>{" "}
              <br />
              <p>
                -<b> MÉTODO PROFESIONAL</b>
                <br />
                <br /> Para obtener una evaluación más precisa y personalizada,
                los profesionales utilizan una técnica llamada <b>draping</b>.
                Este proceso consiste en colocar diferentes telas de colores
                específicos cerca del rostro de la persona para observar cómo
                cada tono afecta su apariencia. El objetivo es identificar los
                colores que mejoran la luminosidad y la armonía del rostro, en
                lugar de aquellos que puedan acentuar sombras, enrojecimientos u
                otras imperfecciones. <br />
                <br />
                <span className="subHeaderInfo">1</span>- El proceso de draping
                comienza con la identificación de la <b>temperatura</b> de color
                de la persona, es decir, si se ven más favorecidos por tonos
                cálidos (amarillos, dorados) o fríos (azules, plateados). <br />
                <br />
                <span className="subHeaderInfo">2</span>- Una vez que se ha
                determinado si la persona es de "temperatura cálida" o
                "temperatura fría", se avanza a analizar la <b>intensidad</b>{" "}
                (colores brillantes o apagados) y la <b>profundidad</b> (colores
                claros u oscuros) que mejor le sientan. <br />
                <br />
                <span className="subHeaderInfo">3</span>- Finalmente, se
                comparan colores de las distintas <b>subestaciones</b> para
                precisar aún más qué paleta específica es la más favorecedora.
                <br /> <br />
                Aunque contar con un buen profesional para realizar este
                análisis es ideal, ahora es posible obtener una evaluación de
                color precisa sin salir de casa gracias a la tecnología. La app{" "}
                <span className="subHeaderInfo">
                  <b>ColorYou</b>
                </span>{" "}
                utiliza los mismos principios y pruebas que un profesional en
                colorimetría. Puedes optar por un análisis estimado o un
                análisis completo, similar al que se haría con draping en
                persona. De esta forma, podrás descubrir tu estación de color
                con precisión, obteniendo una guía completa sobre los colores
                que mejor se adaptan a ti, desde la comodidad de tu hogar.{" "}
                <br />
                <br /> ¡Una herramienta poderosa para resaltar tu belleza
                natural!
              </p>
            </section>
          </article>
        </div>
      </div>
    </div>
  );
};
