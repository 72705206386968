import React from "react";

export const PrivacyTextEs = (props) => {
  return (
    <div id="privacy" className="text-center">
      <div className="containerInfo">
        <div className="section-title">
          <article className="arti">
            <h1 className="headerInfo">Política de Privacidad</h1>
            <p>
              Los desarrolladores de ColorYou han creado la aplicación ColorYou
              como una aplicación Freemium. Este SERVICIO es proporcionado por
              ColorYou de forma gratuita y está destinado a ser utilizado tal
              cual.
            </p>
            <p>
              Esta página se utiliza para informar a los visitantes sobre
              nuestras políticas de recopilación, uso y divulgación de
              información personal si alguien decide utilizar el Servicio.
            </p>
            <p>
              Si decides utilizar el Servicio, aceptas la recopilación y el uso
              de la información de acuerdo con esta política. La información
              personal que recopilamos se utiliza para proporcionar y mejorar el
              Servicio. NO utilizaremos ni compartiremos tu información con
              nadie, excepto según se describe en esta Política de Privacidad.
            </p>
            <p>
              Los términos utilizados en esta Política de Privacidad tienen los
              mismos significados que en nuestros Términos y Condiciones,
              accesibles en ColorYou, a menos que se defina lo contrario en esta
              Política de Privacidad.
            </p>
            <br />
            <p>
              <strong>Recopilación y Uso de la Información</strong>
            </p>
            <br />
            <div>
              <p>
                Para brindarte una mejor experiencia mientras utilizas nuestro
                Servicio, es posible que te solicitemos que nos proporciones
                cierta información personal identificable, incluida pero no
                limitada a la Imagen. Concretamente intentaremos averiguar el
                lenguage que utilizas y puede que el país desde el que usas la
                aplicación para poder mostrarte la información adaptada a ti. La
                información que solicitamos se retendrá SOLAMENTE en tu
                dispositivo y NO será recopilada por nosotros de ninguna manera.
                NUNCA almacenaremos las imágenes que utilices fuera de tu
                dispositivo
              </p>
              <div>
                <p>
                  La aplicación utiliza servicios de terceros que pueden
                  recopilar información utilizada para identificarte.
                </p>
                <p>
                  Enlace a la política de privacidad de los proveedores de
                  servicios de terceros utilizados por la aplicación
                </p>
                <ul>
                  <li>
                    <a
                      href="https://www.google.com/policies/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Servicios de Google Play
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.revenuecat.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      RevenueCat
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <p>
              <strong>Datos de Registro</strong>
            </p>
            <p>
              Queremos informarte que cada vez que uses este Servicio, en caso
              de un error en la aplicación, podemos recopilar datos e
              información (a través de productos de terceros) en tu teléfono
              llamados Datos de Registro. Estos Datos de Registro pueden incluir
              información como tu dirección IP, el nombre de tu dispositivo, la
              versión del sistema operativo, la configuración de la aplicación
              al utilizar el Servicio, la fecha y hora de tu uso del Servicio y
              otras estadísticas.
            </p>
            <p>
              <strong>Cookies</strong>
            </p>
            <p>
              Las cookies son archivos con una pequeña cantidad de datos que se
              utilizan comúnmente como identificadores únicos anónimos. Se
              envían a tu navegador desde los sitios web que visitas y se
              almacenan en la memoria interna de tu dispositivo.
            </p>
            <p>
              Este Servicio NO utiliza explícitamente estas "cookies". Sin
              embargo, la aplicación puede utilizar código y bibliotecas de
              terceros que utilizan "cookies" para recopilar información y
              mejorar sus servicios. Tienes la opción de aceptar o rechazar
              estas cookies y de saber cuándo se envía una cookie a tu
              dispositivo. Si decides rechazar nuestras cookies, es posible que
              no puedas utilizar algunas partes de este Servicio.
            </p>
            <p>
              <strong>Proveedores de Servicios</strong>
            </p>
            <p>
              Podemos emplear compañías e individuos de terceros por las
              siguientes razones:
            </p>
            <ul>
              <li>Para facilitar nuestro Servicio;</li>
              <li>Para proporcionar el Servicio en nuestro nombre;</li>
              <li>Para realizar servicios relacionados con el Servicio; o</li>
              <li>
                Para ayudarnos a analizar cómo se utiliza nuestro Servicio.
              </li>
            </ul>
            <p>
              Queremos informar a los usuarios de este Servicio que estos
              terceros tienen acceso a su Información Personal. La razón es
              realizar las tareas asignadas en nuestro nombre. Sin embargo,
              están obligados a no divulgar ni utilizar la información para
              ningún otro propósito.
            </p>
            <p>
              <strong>Seguridad</strong>
            </p>
            <p>
              Valoramos tu confianza al proporcionarnos tu Información Personal,
              por lo que nos esforzamos por utilizar medios comercialmente
              aceptables para protegerla. Pero recuerda que ningún método de
              transmisión a través de Internet, ni método de almacenamiento
              electrónico es 100 % seguro y confiable, y no podemos garantizar
              su seguridad absoluta.
            </p>
            <p>
              <strong>Enlaces a otros sitios</strong>
            </p>
            <p>
              Este Servicio puede contener enlaces a otros sitios. Si haces clic
              en un enlace de terceros, serás dirigido a ese sitio. Ten en
              cuenta que estos sitios externos no son operados por nosotros. Por
              lo tanto, te recomendamos encarecidamente que revises la Política
              de Privacidad de estos sitios web. No tenemos control sobre el
              contenido, las políticas de privacidad o las prácticas de sitios o
              servicios de terceros.
            </p>
            <p>
              <strong>Privacidad de los niños</strong>
            </p>
            <div>
              <p>
                Este Servicio no se dirige a personas menores de 13 años. No
                recopilamos de manera consciente información de identificación
                personal de niños menores de 13 años. En caso de que descubramos
                que un niño menor de 13 años nos ha proporcionado información
                personal, eliminaremos inmediatamente esta información de
                nuestros servidores. Si eres padre o tutor y eres consciente de
                que tu hijo nos ha proporcionado información personal, ponte en
                contacto conmigo para que pueda tomar las medidas necesarias.
              </p>
              <p>
                <strong>Cambios en esta política de privacidad</strong>
              </p>
              <p>
                Podemos actualizar nuestra Política de Privacidad de vez en
                cuando. Por lo tanto, te recomendamos que revises esta página
                periódicamente para ver si hay cambios. Te informaremos de
                cualquier cambio publicando la nueva Política de Privacidad en
                esta página.
              </p>
              <p>Esta política es efectiva a partir del 2024-03-01</p>
              <p>
                <strong>Contáctanos</strong>
              </p>
              <p>
                Si tienes alguna pregunta o sugerencia sobre nuestra Política de
                Privacidad, no dudes en contactarnos en{" "}
                <span style={{ color: "#DD3D99" }}> coloryouapp@gmail.com</span>
                .
              </p>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};
