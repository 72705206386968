import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  let defL = i18n.language;
  let defaultL = {
    label: defL === "es" ? "Español" : defL === "fr" ? "Français" : "English",
    value: i18n.language,
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "white",
      zIndex: 100,
      marginTop: 6,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        border: 0,
        boxShadow: "none",
        zIndex: 1000,

        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "4DA9B7"
          : isFocused
          ? "white"
          : undefined,
      };
    },
  };

  return (
    <div className="drop-down">
      <Select
        isSearchable={false}
        options={[
          { value: "en", label: "English" },
          { value: "es", label: "Español" },
          { value: "fr", label: "Français" },
        ]}
        defaultValue={defaultL}
        onChange={(e) => {
          i18n.changeLanguage(e.value);
        }}
        styles={colourStyles}
      />
    </div>
  );
};

export default LanguageSwitcher;
