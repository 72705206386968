import React, { useState, useEffect, useRef } from "react";
import { Footer } from "../components/Footer";
import { useTranslation } from "react-i18next";
import ProductList from "../components/ProductList";
import { FileUpload } from "../components/FileUpload";
import { getProducts } from "../utils/getProducts";
import tinycolor from "tinycolor2";
import Navbar from "../components/Navbar";

function Finder() {
  const [currentColor, setCurrentColor] = useState("#ffffff");
  const [selectedColor, setSelectedColor] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [products, setProducts] = useState();
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showCursor, setShowCursor] = useState(false);
  const [dim, setDim] = useState(300);
  const [deviceInfo, setDeviceInfo] = useState("");
  const { t } = useTranslation();
  const divRef = useRef(null);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const width = window.innerWidth;
    const height = window.innerHeight;
    let deviceType = "Computer";
    if (/Android/i.test(userAgent)) {
      deviceType = "Android";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      deviceType = "iPhone";
    }
    setDeviceInfo({ device: deviceType, height: height, width: width });
  }, []);

  useEffect(() => {
    let aux = Math.min(window.innerWidth, 300);
    setDim(aux);

    if (imageDimensions.height > 0) {
      const aspectRatio = imageDimensions.width / imageDimensions.height;

      let iWidth = aux;
      let iHeight = aux;
      if (imageDimensions.width > imageDimensions.height) {
        iHeight = Math.trunc(aux / aspectRatio);
      } else {
        iWidth = Math.trunc(aux * aspectRatio);
      }

      setImageDimensions({ width: iWidth, height: iHeight });
    }
    // eslint-disable-next-line
  }, [window.innerWidth]);

  const isTooDark = (colorInput) => {
    let color = tinycolor(colorInput).toHexString();
    color = color.substring(1);
    let rgb = parseInt(color, 16);
    let r = (rgb >> 16) & 0xff;
    let g = (rgb >> 8) & 0xff;
    let b = (rgb >> 0) & 0xff;
    let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma < 140;
  };

  const handleImageLoad = (e) => {
    const img = e.target;
    const aspectRatio = img.width / img.height;
    let iWidth = dim;
    let iHeight = dim;
    if (img.width > img.height) {
      iHeight = Math.trunc(dim / aspectRatio);
    } else {
      iWidth = Math.trunc(dim * aspectRatio);
    }
    setImageDimensions({ width: iWidth, height: iHeight });
  };

  useEffect(() => {
    let cur = showCursor ? "pointer" : "none";
    document.body.style.cursor = cur;
    return () => {
      document.body.style.cursor = "auto";
    };
  }, [showCursor]);

  const handleMouseMove = (e, orig = 0) => {
    const square = e.currentTarget;
    let x, y;

    if (e.nativeEvent) {
      x = e.nativeEvent.offsetX / square.clientWidth;
      y = e.nativeEvent.offsetY / square.clientHeight;
      setPosition({ x: e.clientX, y: e.clientY });
    } else if (e.touches && e.touches.length > 0) {
      const touch = e.touches[0];
      const rect = square.getBoundingClientRect();
      x = (touch.clientX - rect.left) / square.clientWidth;
      y = (touch.clientY - rect.top) / square.clientHeight;
      setPosition({ x: touch.clientX, y: touch.clientY });
    } else {
      return;
    }

    const canvas = document.createElement("canvas");
    canvas.width = square.clientWidth;
    canvas.height = square.clientHeight;
    const context = canvas.getContext("2d");

    const img = square.querySelector("img");

    if (img) {
      const drawWidth = img.width;
      const drawHeight = img.height;
      context.drawImage(img, 0, 0, drawWidth, drawHeight);

      if (x >= 0 && x <= 1 && y >= 0 && y <= 1) {
        const pixel = context.getImageData(
          x * drawWidth,
          y * drawHeight,
          1,
          1
        ).data;
        const color = `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;
        setCurrentColor(color);

        if (orig !== 0) {
          setSelectedColor(color);
          let data = getProducts(color);
          setProducts(data);
        }
      }
    }
  };

  const preventDragHandler = (e) => {
    e.preventDefault();
  };

  const handleClick = (e) => {
    setShowCursor(true);

    handleMouseMove(e, 1);
    if (deviceInfo.device === "Computer") {
      window.scrollTo({ top: 500, behavior: "smooth" });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        setImageUrl(readerEvent.target.result);

        setSelectedColor("");
      };
      reader.readAsDataURL(file);
    }
  };

  const handleTouchStart = (e) => {
    if (selectedColor !== "") {
      window.scrollTo({ top: 0 });

      const { left, top, right, bottom } =
        divRef.current.getBoundingClientRect();
      const touch = e.touches[0];
      if (
        touch.clientX >= left &&
        touch.clientX <= right &&
        touch.clientY >= top &&
        touch.clientY <= bottom
      ) {
        setShowCursor(true);
        setPosition({ x: touch.clientX, y: touch.clientY });
      }
    }
  };

  const handleTouchMove = (e) => {
    if (showCursor) {
      const touch = e.touches[0];
      const { left, top, right, bottom } =
        divRef.current.getBoundingClientRect();

      if (
        touch.clientX < left ||
        touch.clientX > right ||
        touch.clientY < top ||
        touch.clientY > bottom
      ) {
        setShowCursor(false);
      } else {
        handleMouseMove(e);
      }
    }
  };

  const handleTouchEnd = (e) => {
    const { left, top, right, bottom } = divRef.current.getBoundingClientRect();
    const touch = e.changedTouches[0];

    if (
      touch.clientX >= left &&
      touch.clientX <= right &&
      touch.clientY >= top &&
      touch.clientY <= bottom
    ) {
      const simulatedEvent = {
        currentTarget: e.currentTarget,
        clientX: touch.clientX,
        clientY: touch.clientY,
        nativeEvent: {
          offsetX: touch.clientX - left,
          offsetY: touch.clientY - top,
        },
      };

      handleMouseMove(simulatedEvent, 1);
    } else {
      setShowCursor(false);
    }
  };

  useEffect(() => {
    const divElement = divRef.current;
    divElement.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });
    divElement.addEventListener("touchstart", handleTouchStart, {
      passive: false,
    });
    divElement.addEventListener("touchend", handleTouchEnd, { passive: false });

    return () => {
      divElement.removeEventListener("touchmove", handleTouchMove);
      divElement.removeEventListener("touchstart", handleTouchStart);
      divElement.removeEventListener("touchend", handleTouchEnd);
    };
  }, [showCursor]);

  const handleMouseEnter = () => {
    if (imageUrl !== "") {
      setShowCursor(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setShowCursor(false);
    }
  };

  const handleMouseLeave = () => {
    setShowCursor(false);
  };

  const customCursor = () => {
    return (
      <div
        style={{
          position: "absolute",
          left: `${position.x - 30}px`,
          top: `${position.y - 30}px`,
          backgroundColor: "transparent",
          width: "60px",
          height: "60px",
          borderRadius: "50%",
          pointerEvents: "none",
          zIndex: 10,
          borderWidth: "4px",
          borderColor: "white",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1), 0 16px 24px rgba(0, 0, 0, 0.1), inset 0 0 0 10px ${currentColor}`,
          cursor: "none",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <i
            className="fa fa-crosshairs"
            style={{
              fontSize: "22px",
              color: isTooDark(currentColor) ? "white" : "#333333",
            }}
          ></i>
        </div>
      </div>
    );
  };

  const explanation = (top = true) => {
    return (
      <div
        className="section-title"
        style={{
          paddingRight: 15,
          paddingLeft: 15,
          display: "flex",
          alignItems: "center",
          //    marginBottom: 20,
        }}
      >
        <article className="arti">
          {(top || deviceInfo.device === "Computer") && (
            <h1 className="headerInfo">{t("finderH")}</h1>
          )}
          {top && <p>{t("finderP")}</p>}
        </article>
      </div>
    );
  };

  const explanation2 = () => {
    return (
      <div
        className="section-title"
        style={{
          paddingRight: 15,
          paddingLeft: 15,
          display: "flex",
          alignItems: "center",
          marginBottom: 20,
          fontSize: 16,
        }}
      >
        <article className="arti">
          <p style={{ fontSize: 16 }}>{t("finderI")}</p>
        </article>
      </div>
    );
  };

  return (
    <div className="navbarContainer">
      <div style={{ flex: 1 }}>
        <Navbar />

        {explanation(imageUrl !== "" ? false : true)}

        <div
          style={{
            justifyContent: "center",
            width: "100%",
            display: "flex",

            marginTop:
              imageDimensions.height === 0
                ? deviceInfo.device === "Computer"
                  ? "50px"
                  : "30px"
                : "0px",
            marginBottom: imageDimensions.height === 0 ? "20px" : "0px",
          }}
        >
          <div
            style={{
              height:
                imageDimensions.height === 0 ? dim : imageDimensions.height,
              width: imageDimensions.height === 0 ? dim : imageDimensions.width,
            }}
          >
            <div
              ref={divRef}
              style={{
                height:
                  imageDimensions.height === 0 ? dim : imageDimensions.height,
                width:
                  imageDimensions.height === 0 ? dim : imageDimensions.width,
                backgroundColor:
                  imageDimensions.height === 0 ? "F6F6F6" : "white",
                border: imageDimensions.height === 0 ? "2px solid #F1EBE6" : "",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onMouseMove={handleMouseMove}
              onClick={handleClick}
            >
              {imageUrl === "" && (
                <FileUpload
                  text={t("Select")}
                  handleFileChange={handleFileChange}
                  type="Initial"
                />
              )}
              <img
                id="mainImg"
                src={imageUrl}
                onLoad={handleImageLoad}
                onDragStart={preventDragHandler}
                onContextMenu={(e) => e.preventDefault()}
                style={{
                  touchAction: "none",
                  userSelect: "none",
                  maxWidth: "100%",
                  height: "auto",
                  border: "1px solid silver",
                }}
                alt={imageUrl !== "" ? "Selected" : ""}
              />

              {showCursor &&
                selectedColor !== "" &&
                imageUrl !== "" &&
                customCursor()}
            </div>
          </div>
        </div>

        {imageUrl !== "" && (
          <>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                display: "flex",
                borderWidth: "0px",
                marginTop: "10px",
              }}
            >
              <FileUpload
                text={t("Change")}
                handleFileChange={handleFileChange}
                type="New"
              />
            </div>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                display: "flex",
                borderWidth: "0px",
                marginTop: "10px",
              }}
            >
              {imageUrl !== "" && explanation2()}
            </div>
          </>
        )}

        {selectedColor !== "" && imageUrl !== "" && (
          <>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                display: "flex",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  width: Math.min(window.innerWidth, dim),
                  height: "50px",
                  backgroundColor: selectedColor,
                }}
              ></div>
            </div>
            <div>
              <ProductList
                products={products}
                selectedColor={selectedColor}
                deviceInfo={deviceInfo}
              />
            </div>
          </>
        )}
      </div>

      <Footer device={deviceInfo} />
    </div>
  );
}

export default Finder;
