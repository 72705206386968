import React from "react";

import { InfoTextEs } from "../components/InfoTextEs";
import { InfoText } from "../components/InfoText";
import { Footer } from "../components/Footer";
import { InfoTextFr } from "../components/InfoTextFr";
import { useTranslation } from "react-i18next";
import Navbar from "../components/Navbar";

function Info() {
  const { i18n } = useTranslation();
  let lang = i18n.language;

  return (
    <div style={{ cursor: "auto" }}>
      <Navbar />

      {lang === "fr" && <InfoTextFr />}
      {lang === "es" && <InfoTextEs />}
      {lang !== "es" && lang !== "fr" && <InfoText />}
      <Footer />
    </div>
  );
}

export default Info;
