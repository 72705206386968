import React from "react";
import ProductItem from "./ProductItem";
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const renderTriangle = (selectedColor) => {
  if (selectedColor === "") return null;

  return (
    <div
      onClick={scrollToTop}
      style={{
        position: "relative",
        width: "0",
        height: "0",
        borderLeft: "40px solid transparent",
        borderRight: "40px solid transparent",
        borderBottom: `60px solid ${selectedColor}`,
        cursor: "pointer",
        margin: "0 auto",
        marginTop: "40px",
        marginBlock: "5px",
      }}
    >
      <div
        style={{
          content: '""',
          position: "absolute",
          top: "0",
          left: "-40px", // Debe coincidir con el tamaño del borde izquierdo
          width: "0",
          height: "0",
          borderLeft: "40px solid transparent",
          borderRight: "40px solid transparent",
          borderBottom: `60px solid rgba(0, 0, 0, 0.2)`, // Sombra más oscura
          zIndex: "-1", // Coloca la sombra detrás del triángulo principal
          transform: "translate(3px, 3px)", // Desplaza la sombra para el efecto 3D
        }}
      />
    </div>
  );
};
const ProductList = ({ products, id, selectedColor, deviceInfo }) => {
  return (
    <>
      <div className="product-list-container">
        {products.map((product, index) => (
          <ProductItem key={product[1] + index} product={product} id={id} />
        ))}

        {/*products.length > 1 &&
        products[1].map((product, index) => (
          <ProductItem key={product[1] + index} product={product} />
        ))*/}
      </div>
      {(deviceInfo.device !== "Computer" || products.length > 5) &&
        renderTriangle(selectedColor)}
    </>
  );
};

export default ProductList;
