import React, { useState } from "react";
import { useTranslation } from "react-i18next";
export const Masking = (props) => {
  const [mask, setMask] = useState(0);
  const { t } = useTranslation();
  return (
    <div className="containerI">
      <div className="containerImg">
        <img
          src="img/Info/girl.jpg"
          alt="Imagen base"
          className="base-image"
          onDragStart={(e) => e.preventDefault()}
          onContextMenu={(e) => e.preventDefault()}
          style={{ opacity: mask === 4 ? 0 : 1 }}
        />

        {mask !== 3 && (
          <div
            className={
              mask === 2 || mask === 4
                ? "overlay-containerFull"
                : mask === 1
                ? "overlay-containerLeft"
                : "overlay-container"
            }
          >
            <img
              src="img/Info/girl_overlay.png"
              alt={t("ImageOverlay")}
              className={
                mask === 0
                  ? "overlay-image"
                  : mask === 1
                  ? "overlay-imageLeft"
                  : "overlay-imageFull"
              }
              onDragStart={(e) => e.preventDefault()}
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        )}
      </div>

      <div className="button-container">
        <button className="buttonInfo" onClick={() => setMask(0)}>
          {t("HideRight")}
        </button>
        <button className="buttonInfo" onClick={() => setMask(1)}>
          {t("HideLeft")}
        </button>
        <button className="buttonInfo" onClick={() => setMask(2)}>
          {t("HideAll")}
        </button>
        <button className="buttonInfo" onClick={() => setMask(3)}>
          {t("OriginalImage")}
        </button>
        <button className="buttonInfo" onClick={() => setMask(4)}>
          {t("OnlyMask")}
        </button>
      </div>
    </div>
  );
};
