import React, { useState, useEffect } from "react";
//import { Navigation } from "../components/navigation";
import { Footer } from "../components/Footer";
import { useTranslation } from "react-i18next";
import ProductList from "../components/ProductList";
import Navbar from "../components/Navbar";

function ForYou() {
  const products = [
    ["Bottega Verde - Fiori d'Oriente", "B0CL7HYJQ5", "519j323oD5L"],
    ["Xiaomi Redmi Buds 4 Lite", "B0BTSXHNN1", "41cGfxA3MHL"],
  ];

  const [deviceInfo, setDeviceInfo] = useState("");
  const { t } = useTranslation();

  //If something goes wrong maybe this work https://stackblitz.com/edit/stackblitz-starters-7dwufu?file=src%2FApp.js
  useEffect(() => {
    const userAgent = navigator.userAgent;
    const width = window.innerWidth;
    const height = window.innerHeight;
    let deviceType = "Computer";
    if (/Android/i.test(userAgent)) {
      deviceType = "Android";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      deviceType = "iPhone";
    }
    setDeviceInfo({ device: deviceType, height: height, width: width });
  }, []);

  const explanation = (top = true) => {
    return (
      <div
        className="section-title"
        style={{
          paddingRight: 15,
          paddingLeft: 15,
          display: "flex",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <article className="arti">
          <h1 className="headerInfo">{t("forYouH")}</h1>
          {top && <p>{t("forYouP")}</p>}
        </article>
      </div>
    );
  };

  return (
    <div
      style={{
        height: "100%",
        paddingTop: "100px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        cursor: "auto",
        //  cursor: "pointer",
      }}
    >
      <div style={{ flex: 1 }}>
        {<Navbar />}

        {explanation(true)}

        <div>
          <ProductList products={products} id="forYou" />
        </div>
      </div>

      {<Footer device={deviceInfo} />}
    </div>
  );
}

export default ForYou;
