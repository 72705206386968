import { data } from "../data/ama";
import tinycolor from "tinycolor2";
export const hslf = (color) => {
  var rgb = [
    parseInt("0x" + color.substring(1, 3)) / 255,
    parseInt("0x" + color.substring(3, 5)) / 255,
    parseInt("0x" + color.substring(5, 7)) / 255,
  ];
  let min, max, delta, h, s, l;
  var r = rgb[0],
    g = rgb[1],
    b = rgb[2];

  min = Math.min(r, Math.min(g, b));
  max = Math.max(r, Math.max(g, b));

  delta = max - min;
  l = (min + max) / 2;

  s = 0;
  if (l > 0 && l < 1) {
    s = delta / (l < 0.5 ? 2 * l : 2 - 2 * l);
  }

  h = 0;
  if (delta > 0) {
    if (max === r && max !== g) {
      h += (g - b) / delta;
    }
    if (max === g && max !== b) {
      h += 2 + (b - r) / delta;
    }
    if (max === b && max !== r) {
      h += 4 + (r - g) / delta;
    }
    h /= 6;
  }

  return [h * 255, s * 255, l * 255];
};

const rgbf = (color) => {
  return [
    parseInt("0x" + color.substring(1, 3)),
    parseInt("0x" + color.substring(3, 5)),
    parseInt("0x" + color.substring(5, 7)),
  ];
};
export const getProducts = (id) => {
  let list = data;

  let color = id;
  if (id.substring(0, 3) === "rgb") {
    color = "#" + tinycolor(id).toHex();
  }
  color = color.toUpperCase();
  if (color.length < 3 || color.length > 7) {
    return { name: "Invalid Color", index: -1 };
  }
  if (color.length % 3 === 0) {
    color = "#" + color;
  }
  if (color.length === 4) {
    color =
      "#" +
      color.substring(1, 1) +
      color.substring(1, 1) +
      color.substring(2, 1) +
      color.substring(2, 1) +
      color.substring(3, 1) +
      color.substring(3, 1);
  }

  var rgb = rgbf(color);

  var r = rgb[0],
    g = rgb[1],
    b = rgb[2];

  var hsl = hslf(color);
  var h = hsl[0],
    s = hsl[1],
    l = hsl[2];
  var ndf1 = 0;
  let ndf2 = 0;
  let ndf = 0;
  let cl = -1,
    df = -1;
  /* cl2 = -1,
    df2 = -1;*/

  for (var i = 0; i < list.length; i++) {
    let orig = list[i][0];

    let inverted = orig.split("").reverse().join("");

    if (color === "#" + inverted) {
      return data[i][1];
    }
    let rgbl = rgbf("#" + inverted);
    let rl = rgbl[0],
      gl = rgbl[1],
      bl = rgbl[2];

    let hsll = hslf("#" + inverted);
    var hl = hsll[0],
      sl = hsll[1],
      ll = hsll[2];
    let list2 = ["#" + inverted, rl, gl, bl, hl, sl, ll];
    ndf1 =
      Math.pow(r - +list2[1], 2) +
      Math.pow(g - +list2[2], 2) +
      Math.pow(b - +list2[3], 2);
    ndf2 =
      Math.pow(h - +list2[4], 2) +
      Math.pow(s - +list2[5], 2) +
      Math.pow(l - +list2[6], 2);
    ndf = ndf1 + ndf2 * 2;
    if (df < 0 || df > ndf) {
      // cl2 = cl;
      df = ndf;
      cl = i;
    }
  }

  return data[cl][1];
};
