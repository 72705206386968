import React from "react";

import BeforeAfterSlider from "react-before-after-slider";
const Comparator = () => {
  let before = "../img/makeup/MakeupGirlBefore.jpg";
  let after = "../img/makeup/MakeupGirlAfter.jpg";
  const preventDragHandler = (e) => {
    e.preventDefault();
  };
  return (
    <div
      className="col-xs-12 col-md-6"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "304px",
          backgroundColor: "silver",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          borderRadius: "45px",
          display: "flex",
          paddingTop: "12px",
          paddingBottom: "12px",
          boxShadow: "11px 11px 14px rgba(0,0,0,.08)",
          border: "1px solid #BAB8B6",
        }}
        onDragStart={preventDragHandler}
      >
        <div
          style={{
            width: "280px",
            backgroundColor: "silver",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            borderRadius: "35px",
            display: "flex",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "50%",
              transform: "translateX(-50%)",
              width: "30%",
              height: "13px",
              backgroundColor: "silver",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              boxShadow: "3px 1px 14px rgba(0,0,0,0.2)",
              zIndex: 100,
            }}
            onDragStart={preventDragHandler}
          />
          <BeforeAfterSlider
            before={after}
            after={before}
            width={280}
            height={610}
          />
        </div>
      </div>
    </div>
  );
};
export default Comparator;
