export const brands = [
  "7 For ALL Mankind",
  "adidas",
  "Allegra K",
  "Amazon Aware",
  "Amazon Essentials",
  "Armani Exchange",
  "ASICS",
  "AURIQUE",
  "Avancel",
  "AXETVSOK",
  "Barbour",
  "BARBOUR",
  "Barbour International",
  "blue seven",
  "BOSS",
  "Boss",
  "BURBERRY",
  "Buttoned Down",
  "Calvin Klein",
  "CALVIN KLEIN",
  "Calvin Klein Jeans",
  "calvin klein jeans",
  "Champion",
  "CHEROKEE",
  "Cherokee Uniformes",
  "CHIARA FERRAGNI",
  "Chiara Ferragni",
  "Cinque",
  "COACH",
  "Colmar",
  "COLMAR",
  "colmar",
  "Columbia",
  "Converse",
  "Diesel",
  "Dolce & Gabbana",
  "DONDUP",
  "DSQUARED2",
  "EA7",
  "ECOALF",
  "Emporio Armani",
  "FANGJIN",
  "Fila",
  "Fred Perry",
  "GANT",
  "Geifa",
  "Geox",
  "Gocco",
  "Goodthreads",
  "Guess",
  "GUESS",
  "Gyabnw",
  "Hackett London",
  "HUGO",
  "Hugo Boss",
  "Hunter",
  "Jimmy Lion", //free to use
  "JINKESI",
  "John Smith",
  "Jordan",
  "KARL LAGERFELD",
  "Kenzo",
  "Kisscynest",
  "KTILG",
  "Lacoste",
  "LaLaLa",
  "Levi's",
  "Lianlive",
  "Liu Jo",
  "Love Moschino",
  "LUYAA",
  "Making The Cut",
  "MASADA",
  "Mayoral",
  "Meilicloth",
  "MERAKI",
  "Merry Style",
  "Michael Kors",
  "MICHAEL Michael Kors",
  "Mimpo",
  "MOSCHINO",
  "Muzboo",
  "Napapijri",
  "Navahoo",
  "New Balance",
  "NIKE",
  "Nike",
  "nike",
  "Nozone",
  "Oakley",
  "OcioDual",
  "Outdoor Ventures",
  "PACIFIC AND COLORS",
  "Patagonia",
  "PATRIZIA PEPE",
  "PEAK PERFORMANCE",
  "Peak Performance",
  "Pepe Jeans",
  "PEPE JEANS",
  "Petit Bateau",
  "Pierre Cardin",
  "Pinko",
  "Pizuna",
  "Polo Ralph Lauren",
  "PROMISE",
  "PUMA",
  "Puma",
  "QUEENIEKE",
  "RAINS",
  "Rains",
  "Ralph Lauren",
  "rare/self",
  "Reebok",
  "Regalos Berenjenas",
  "REORIA",
  "REPLAY",
  "Roy Robson",
  "SALOMON",
  "Saloogoe",
  "Sam Edelman",
  "Save The Duck",
  "schott",
  "Schott NYC",
  "Scotch & Soda",
  "Seafolly",
  "SEDEX",
  "Simple&Opulence",
  "Steve Madden",
  "STHUGE",
  "Ted Baker",
  "TEREA",
  "The Drop",
  "The North Face",
  "Timberland",
  "Tommy Hilfiger",
  "Tommy Jeans",
  "tommy jeans",
  "TRAMAS+",
  "True Religion",
  "Undercover",
  "undercover lingerie",
  "Vans",
  "VANS",
  "Versace",
  "VERSACE JEANS COUTURE",
  "Veryoung",
  "Viottiset",
  "Wacoal",
  "Wolford",
  "WOOLRICH",
  "Wrangler",
  "XIEERDUO",
  "Zunea",
];
