import React from "react";
import { useTranslation } from "react-i18next";
import constants from "../data/constants";

import { HashLink as Link } from "react-router-hash-link";

export const Footer = (props) => {
  const { t } = useTranslation();

  return (
    <footer>
      <div id="footer">
        {window.innerWidth < 400 && (
          <div
            className="container text-center"
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              paddingLeft: "2px",
              paddingRight: "2px",
              paddingBottom: "0px",
            }}
          >
            <p>&copy; {constants.year} ColorYou </p>
            <p>
              <Link to={"/terms#page-top"} className="footer">
                {t("Terms")}
              </Link>
              {"   |   "}
              <Link to={"/privacy#page-top"} className="footer">
                {t("Privacy")}
              </Link>
            </p>
          </div>
        )}
        {window.innerWidth > 399 && (
          <div className="container text-center">
            <p>
              &copy; {constants.year} ColorYou |{"    "}
              <Link to={"/terms#page-top"} className="footer">
                {t("Terms")}
              </Link>
              {"   |   "}
              <Link to={"/privacy#page-top"} className="footer">
                {t("Privacy")}
              </Link>
            </p>
          </div>
        )}
      </div>
    </footer>
  );
};
